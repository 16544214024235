import React from "react"
import { graphql } from "gatsby"
import { PortableText } from "@portabletext/react"
import Layout from "../../../components/layout"
import DualSplit from "../../../components/sections/dual-split"
import { GatsbyImage } from "gatsby-plugin-image"
import { getNiceDate } from "../../../utils/date"
import Seo from "../../../components/seo"

const SinglePost = ({ data }) => {
  const news = data.sanityPost
  
  return (
    <Layout>
      <Seo
        title={news.title}
        description={news.description}
        image={news.mainImage.asset.seo.images.fallback.src}
      />
      <section className="section">
        <div className="container is-max-desktop">
          <h1 className="title is-1 has-text-centered">{news.title}</h1>
          <p className="subtitle is-4 has-text-centered">
            {getNiceDate(news.publishedAt)}
          </p>
          <div className="columns is-centered">
            <div className="column is-8">
              <div className="mb-6">
                <GatsbyImage image={news.mainImage.asset.hero} />
              </div>
              <div className="content">
                <PortableText value={news._rawBody} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <DualSplit />
    </Layout>
  )
}
export default SinglePost

export const query = graphql`
  query ($id: String, $language: String!) {
    sanityPost(id: { eq: $id }) {
      id
      _rawBody(resolveReferences: { maxDepth: 10 })
      language
      title
      description
      _createdAt
      publishedAt
      _updatedAt
      slug {
        current
      }
      mainImage {
        asset {
          hero: gatsbyImageData(width: 1280, height: 720)
          seo: gatsbyImageData(width: 640, height: 480)
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
